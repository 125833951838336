import $, { event } from "jquery";

$(document).ready(function () {
  $(".finalFormFail").hide();
  $(".finalFormSuccess").hide();
  $("#successSecondStep").hide();
  $(".formstatus").hide();
  let current_fs, next_fs, previous_fs; //fieldsets
  let opacity;
  let answers = [];

  const origin = window.location.origin;

  $("#tellMeMoreBtn").on("click", function (ev) {
    ev.preventDefault();
    $("#successFirstStep").hide();
    $("#successSecondStep").show();
  });

  $(".debtFormBtn").on("click", function () {
    current_fs = $(this).parent().parent().parent();
    next_fs = current_fs.next();

    answers.push($(this).data("value"));
    //Add Class Active
    $(".progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

    //show the next fieldset
    next_fs.show();
    //hide the current fieldset with style
    current_fs.animate(
      { opacity: 0 },
      {
        step: function (now) {
          // for making fieldset appear animation
          opacity = 1 - now;

          current_fs.css({
            display: "none",
            position: "relative",
          });
          next_fs.css({ opacity: opacity });
        },
        duration: 600,
      }
    );

    if (answers.length === 5) {
      let success = true;
      $(".progressbar").hide();
      if (answers[1] == 1 || answers[2] == 1 || answers[4] == 1) {
        success = false;
      }

      if (success === true) {
        $(".finalFormSuccess").show();
        return;
      } else {
        $(".finalFormFail").show();
      }
    }
  });

  function success() {
    console.log("send success");
    // check to see if user is on wizard page: if yes, redirect to homepage
    if (window.location.pathname.includes("eligibility-test")) {
      window.location.href = `${origin}?submit=true`;
    }
    // else change the form status
    $(".formWrapper").hide();
    $(".formstatus").html(
      '<div class="alert alert-success" role="alert">Thanks! We\'ll be in touch soon.</div>'
    );
    $(".formstatus").show();
  }

  function error() {
    console.error("send failed");

    $(".formWrapper").hide();
    $(".formstatus").html(
      '<div class="alert alert-danger" role="alert">Oops! There was a problem. Please try again later.</div>'
    );
    $(".formstatus").show();
  }

  // handle the form submission event
  $(".eligibleSuccessFormBtn").on("click", function (ev) {
    ev.preventDefault();

    let valid = true;
    let querySource = getUrlParameter("source");
    const source = querySource == "" ? "organic" : querySource;

    const firstName = $(this)
      .closest(".formWrapper")
      .find("input#firstName")
      .val();
    const lastName = $(this)
      .closest(".formWrapper")
      .find("input#lastName")
      .val();
    const phone = $(this).closest(".formWrapper").find("input#phone").val();
    const email = $(this).closest(".formWrapper").find("input#email").val();

    if (firstName === "") {
      valid = false;
    }

    if (phone === "" && email === "") {
      valid = false;
    }

    if (!valid) {
      console.error("Form invalid");
      return false;
    }

    // Display the key/value pairs in form for testing
    // var formDataEntries = data.entries(),
    //   formDataEntry = formDataEntries.next(),
    //   pair;
    // while (!formDataEntry.done) {
    //   pair = formDataEntry.value;
    //   console.log(pair[0] + ", " + pair[1]);
    //   formDataEntry = formDataEntries.next();
    // }
    // End logging

    const zeroIndexedAnswers = answers.map((a) => a - 1);

    const data = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      phone_mobile: phone,
      lead_source: source + " / trustedscotland.com",
      origin: origin,
      lives_in_scotland: scotland[zeroIndexedAnswers[0]],
      total_owed: debt[zeroIndexedAnswers[1]],
      employment_status: employment[zeroIndexedAnswers[2]],
      how_much_paying: payingNow[zeroIndexedAnswers[3]],
      how_much_afford: payingNow[zeroIndexedAnswers[4]],
    };
    const json = JSON.stringify(data);

    const form = {
      action: "/formforwarder",
      method: "POST",
    };

    ajax(form.method, origin + form.action, json, success, error);
  });
});

function ajax(method, url, data, success, error) {
  var xhr = new XMLHttpRequest();
  xhr.open(method, url);
  xhr.setRequestHeader("Accept", "application/json");
  xhr.onreadystatechange = function () {
    if (xhr.readyState !== XMLHttpRequest.DONE) return;
    if (xhr.status === 200) {
      success(xhr.response, xhr.responseType);
    } else {
      error(xhr.status, xhr.response, xhr.responseType);
    }
  };
  xhr.send(data);
}

const scotland = ["No", "Yes"];
const debt = [
  "£0 - £4k",
  "£4k - £10k",
  "£10k- £15k",
  "£15k - £20k",
  "£20k - £30k",
  "Over £30k",
];
const employment = [
  "Unemployed",
  "Employed Full Time",
  "Employed Part Time",
  "Self Employed",
  "Student",
  "Retired",
];
const payingNow = [
  "£0 - £99",
  "£100 - £149",
  "£150 - £199",
  "£200 - £249",
  "£250 - £299",
  "Over £300",
];
const afford = [
  "£0 - £99",
  "£100 - £149",
  "£150 - £199",
  "£200 - £249",
  "£250 - £299",
  "Over £300",
];

function getUrlParameter(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}
